/* The Modal (background) */
.ebcf_modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 200px; /* Location of the box */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    /*background-color: rgb(0,0,0);  Fallback color */
    /*background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}



/* Modal Content */
.ebcf_modal-content {
    background-color: #fefefe;
    transform: translateX(10vw);
    border: 0 solid #888;
    width: 370px;
    position: relative
}



@media (max-width: 767px) {
    .ebcf_modal {
        padding-top: 100px; /* Location of the box */
    }

    .ebcf_modal-content {
        transform: translateX(0);
    }
    
}



.ebcf_modal-content > img {
    width : 100%;
}

/* The Close Button */
.ebcf_close {
    color: #494949;
    position: absolute;
    font-size: 40px;
    font-weight: bold;
    right: 30px;
    top : 20px;
    line-height: 1.5rem;
}

.ebcf_close:hover,
.ebcf_close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}